import React from "react";
import "../Styles/about.css"; // Import your custom CSS file
export default function About() {
	return (
        <>  
        <section id="about" class="about">
                <div class="container">
                <div class="about-content">
                    <div class="row">
                        <div class="col-6">
                            <img src="/assets/images/about-us1.png" class="company-img" alt="" srcset=""/>
                        </div>
                        <div class="col-6">
                            <div class="about-content-detial">
                                <h1>ABOUT</h1>
                          <p>
                          PepewifAI was born out of a desire to inject a fresh, playful spirit into the crypto market while harnessing the power of AI to enhance its utility and performance. In a world brimming with serious financial instruments and complex technologies, BabyAIPepe stands out as a beacon of joy and simplicity, reminding us that finance can be fun and accessible to all.
                            <br /> <br />
                            The creators of PepewifAI envisioned a token that could do more than just ride the waves of market trends. They wanted to create a community-driven project that leverages AI to analyze market data, predict trends, and even contribute to the development of the project itself. This vision led to the creation of a memecoin with a purpose, one that offers both entertainment and value to its holders.
                        </p>
                    </div>
                        </div>
                    </div>
                </div>
            </div>
            </section>
        </>
    );
    }