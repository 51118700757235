import React from "react";
import "../Styles/buy.css"; // Import your custom CSS file

export default function Buy() {
	return (
		<>
			<section id="buy" class="buy">
				<div class="container">
					<div class="buy-content">
						<h1>HOW TO BUY</h1>
					</div>
					<div class="buy-box">
						<div class="row">
							<div class="col-3">
								<img
									src="/assets/images/21.webp"
									class="buy-box-img"
									alt=""
									srcset=""
								/>
							</div>
							<div class="col-9">
								<div class="buy-box-content">
									<h1>Create a Wallet</h1>
									<p>
										download metamask or your wallet of choice from the app
										store or google play store for free. Desktop users, download
										the google chrome extension by going to metamask.io.
									</p>
								</div>
							</div>
						</div>
					</div>
					<div class="buy-box">
						<div class="row">
							<div class="col-3">
								<img
									src="/assets/images/eth.webp"
									class="buy-box-img"
									alt=""
									srcset=""
								/>
							</div>
							<div class="col-9">
								<div class="buy-box-content">
									<h1>Get Some ETH</h1>
									<p>
										have ETH in your wallet to switch to $PEPE. If you don’t
										have any ETH, you can buy directly on metamask, transfer
										from another wallet, or buy on another exchange and send it
										to your wallet.
									</p>
								</div>
							</div>
						</div>
					</div>
					<div class="buy-box">
						<div class="row">
							<div class="col-3">
								<img
									src="/assets/images/uniswap.webp"
									class="buy-box-img"
									alt=""
									srcset=""
								/>
							</div>
							<div class="col-9">
								<div class="buy-box-content">
									<h1>Go to Uniswap</h1>
									<p>
										connect to Uniswap. Go to app.uniswap.org in google chrome
										or on the browser inside your Metamask app. Connect your
										wallet. Paste the $PEPE token address into Uniswap, select
										Pepe, and confirm. When Metamask prompts you for a wallet
										signature, sign.
									</p>
								</div>
							</div>
						</div>
					</div>
					<div class="buy-box">
						<div class="row">
							<div class="col-3">
								<img
									src="/assets/images/about-us1.png"
									class="buy-box-img"
									alt=""
									srcset=""
								/>
							</div>
							<div class="col-9">
								<div class="buy-box-content">
									<h1>Switch ETH for $PWAI</h1>
									<p>
										switch ETH for $PWAI. We have ZERO taxes so you don’t need
										to worry about buying with a specific slippage, although you
										may need to use slippage during times of market volatility.
									</p>
								</div>
							</div>
						</div>
					</div>
					<div class="buy-content">
						<h1>Contract Address</h1>
						<div class="buy-box">
							<div class="row">
								<div class="col-12">
									<div class="buy-box-content">
										<h1>
											<a href="https://etherscan.io/address/0x6982508145454ce325ddbe47a25d4ec3d2311933">
												etherscan link
											</a>
										</h1>
									</div>
								</div>
							</div>
						</div>
					</div>
                    <div class="buy-content">
						<h1>Chart</h1>
						<div class="buy-box">
							<div class="row">
								<div class="col-12">
									<div class="buy-box-content">
										<h1><a href="https://www.dextools.io/app/en/ether/pair-explorer/0xa43fe16908251ee70ef74718545e4fe6c5ccec9f?t=1713550388867">
                								Dextool
            								</a>
										</h1>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}
