import React from "react";
import "../Styles/company.css"; // Import your custom CSS file

export default function Company() {
	return (
		<>
			<section id="#Home" class="company">
				<div class="div-bg">
					<div class="container">
						<div class="company-details">
							<div class="row">
								<div class="col-6">
									<div class="company-heading">
										<h1>PepewifAI</h1>
										<p>
											In the vast and ever-evolving universe of cryptocurrency,
											a new star is rising, destined to illuminate the space
											with its unique charm and potential. This star is none
											other than PepewifAI, a memecoin that transcends the
											traditional boundaries of digital currency, blending the
											whimsical allure of meme culture with the groundbreaking
											capabilities of artificial intelligence. AI Pepewif isn't
											just another addition to the crypto zoo; it's a symbol of
											innovation, community, and the future of finance, all
											wrapped up in a package that's as entertaining as it is
											promising.
										</p>
								<div class="col-6">
									<img
										src="/assets/images/bc-1.png"
										class="company-img"
										alt=""
										srcset=""
									/>
								</div>
										{/* <button class="ul-button">Buy Now</button> */}

										<button class="ul-button">Claim Now</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}
