import React, { useState } from "react";
import "../Styles/header.css"; // Import your custom CSS file
import { useAccount, useConnect } from "wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/react";
export default function Header() {
	const { address, isConnected } = useAccount();
	console.log(address);
	const { open } = useWeb3Modal({
		key: address, // Use the wallet address as a key
	});
	// onclick button function
	const connectWallet = async () => {
		await open();
	};
	return (
		<>
			<header class="header">
				<div class="container">
					<div class="menu">
						<div class="row">
							<div class="col-3">
								<div class="logo">
									<a href="#Home">
										<img
											loading="lazy"
											src="/assets/images/AIPepewif logo.png"
											alt=""
											srcset=""
											className="logo-img"
										/>
									</a>
								</div>
							</div>
							<div class="col-9">
								<nav>
									<ul>
										<li>
											<a class="active" href="#Home">
												Home
											</a>
										</li>
										<li>
											<a href="#about">About</a>
										</li>
										<li>
											<a href="#buy">How To Buy</a>
										</li>
										<li>
											<a href="#TOKENOMICS">TOKENOMICS</a>
										</li>
										<li>
											<a href="#roadmap">ROADMAP</a>
										</li>
										{/* <button class="ul-button">Buy Now</button> */}

										{
											// If the user is connected, display the address
											isConnected && (
												<button
													class="ul-button-connect"
													onClick={connectWallet}
												>
													{address.slice(0, 6)}...{address.slice(-4)}
												</button>
											)
										}
										{
											// If the user is not connected, display the button
											!isConnected && (
												<button
													class="ul-button-connect"
													onClick={connectWallet}
												>
													Connect Wallet
												</button>
											)
										}
									</ul>
								</nav>
							</div>
						</div>
					</div>
				</div>
			</header>
		</>
	);
}
