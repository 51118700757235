import React from "react";
import Header from "./components/header";
import Company from "./components/company";
import About from "./components/about";
import Buy from "./components/buy";
import Token from "./components/token";
import Roadmap from "./components/roadmap";
import Contact from "./components/contact-us";
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";

import { WagmiConfig } from "wagmi";
import { arbitrum } from "viem/chains";

const metadata = {
	name: "AIPEPE",
	description: "AIPEPE",
	url: "",
	icons: ["https://avatars.githubusercontent.com/u/37784886"],
};
const projectId = "90bf105ef4aa4e1e16a2d0284385ed7a";
const chains = [arbitrum];
const wagmiConfig = defaultWagmiConfig({
	chains,
	projectId,
	metadata,
	enableAnalytics: true, // Optional - defaults to your Cloud configuration
});
createWeb3Modal({ wagmiConfig, projectId, chains });
function App() {
	return (
		<WagmiConfig config={wagmiConfig}>
			<div id="app">
				<Header />
				<Company />
				<About />
				<Buy />
				<Token />
				<Roadmap />
				<Contact />
			</div>
		</WagmiConfig>
	);
}

export default App;
