import React from "react";
import "../Styles/roadmap.css"; // Import your custom CSS file

export default function Roadmap() {
	return (
        <>
        <section class="roadmap" id="roadmap">
            <div class="container">
                <div class="token-header">
                    <h1>ROADMAP</h1>
                </div>
                <div class="row">
                    <div class="col-3">
                        <img src="/assets/images/roadmap1.png" class="roadmap-box-img" alt="" srcset=""/>
                    </div>
                    <div class="col-6">
                        <div class="roadmap-box">
                            <p>Phase 1: Meme</p>
                            <p>Phase 2: Vibe and HODL</p>
                            <p>Phase 3: Meme Takeover</p>
                        </div>
                    </div>
                    <div class="col-3">
                        <img src="/assets/images/bc-1.png" class="roadmap-box-img" alt="" srcset=""/>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
    }


