import React from "react";
import "../Styles/token.css"; // Import your custom CSS file

export default function Token() {
	return (
        <>
          <section className="token" id="TOKENOMICS">
                <div className="container">
                    <div className="token-header">
                        <h1>TOKENOMICS</h1>
                    </div>
                    <div className="token-content">
                        <div className="row">
                            <h2>Token Supply: <br/> 100 Million</h2>
                            <div className="col-8">
                                <div className="token-box">
                                    <p> 6% on every buy and sell. 2% going to marketing wallet, 2% burn, 2% liquidity pool</p>
                                    <br/>
                                    <p>
                                    No team allocation, no roadmap. Pure memecoin with 100% token toward liquidity. Stealth fair launch, clean contract with locked liquidity. No airdrop and everyone has to buy from the market,  pure memecoin by and for the community.
                                    </p>
                            </div>
                            </div>
                            <div className="col-4">
                                <img src="/assets/images/token1.png" className="token-box-img" alt="" srcset=""/>
                            </div>
                        </div>
                </div>
                </div>
            </section>

        </>
    );
    }