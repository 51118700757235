import React from "react";
import "../Styles/contact-us.css"; // Import your custom CSS file

export default function Contact() {
	return (
        <>
        <section class="contact-us">
                <div class="container">
                    <div class="token-header">
                        <h1>CONTACT</h1>
                    </div>
                    <img src="/assets/images/AIPepewif logo.png" class="rounded mx-auto d-block contact-us-logo" alt="..."/>
                   
                        <div class="contact-us-logo-d">
                            <div class="row">
                                <div class="col-6">
                                    <a href="https://t.me/BOOK_OF_MEME">
                                        <img src="/assets/images/tele.webp" class="contact-us-tele" alt="" srcset=""/>
                                    </a>
                                </div>
                                <div class="col-6">
                                    <a href="https://twitter.com/Darkfarms1">
                                        <img src="/assets/images/twitter.webp" class="contact-us-twitter" alt="" srcset=""/>
                                    </a>
                                </div>
                            </div>
                        <div class="contact-us-content">
                     
                        </div>
                        </div>
                </div>
            </section>

        </>
    );
    }